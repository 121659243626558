<template>
    <div class="avue-crud">
      <div class="serchView">
        <el-form :inline="true">
            <el-form-item :label='$t("adminMenu.name1")' prop="menuName">
                <el-input v-model="menuName" :placeholder="$t('inputTit.shuru' )" clearable />
            </el-form-item>
            <el-form-item>
                <el-button type="primary"  @click="getList" >{{$t("button.search")}}</el-button>
                <el-button v-if="sys_menu_add"  type="primary" 
                    @click="addOrUpdateHandle(false)"> {{$t("button.add")}} </el-button>
            </el-form-item>
        </el-form>
      </div>

    <div class="dataList">
      <el-table border  v-loading="loading" :data="menuList" height="calc(100vh - 300px)"
        row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildrens'}">
        <el-table-column prop="name" :label='$t("adminMenu.name1")' :show-overflow-tooltip="true" min-width="180"></el-table-column>
        <el-table-column prop="icon" :label='$t("adminMenu.name2")' align="center" width="100">
            <template #default='scope'>
                <i :class="scope.row.icon"></i>
            </template>
        </el-table-column>
        <el-table-column prop="sortOrder" :label='$t("adminMenu.name3")' width="60"></el-table-column>
        <el-table-column prop="path" :label='$t("adminMenu.name4")' :show-overflow-tooltip="true" min-width="200"></el-table-column>
        <el-table-column prop="menuType" :label='$t("adminMenu.name5")' width="80" align="center">
            <template #default='scope'>
                <el-tag type="success" v-if="scope.row.menuType === '0'">{{$t("adminMenu.name5_1")}}</el-tag>
                <el-tag type="success" v-if="scope.row.menuType === '2'">{{$t("adminMenu.name5_2")}}</el-tag>
                <el-tag type="info" v-if="scope.row.menuType === '1'">{{$t("adminMenu.name5_3")}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="permission" :label='$t("adminMenu.quanxian")' :show-overflow-tooltip="true" width="170"></el-table-column>
        <el-table-column  :label='$t("adminMenu.caozuo")' align="center" min-width="190">
            <template #default='scope'>
                <el-button size="small" text type="primary" 
                @click="addOrUpdateHandle(false,scope.row)" v-if="sys_menu_add && scope.row.menuType !== '1'">{{$t("button.add")}}  </el-button>
                <el-button size="small" text  type="primary" @click="addOrUpdateHandle(true,scope.row)" v-if="sys_menu_edit">{{$t("button.update")}}</el-button>
                <el-button size="small" text type="primary" @click="handleDelete(scope.row)" v-if="sys_menu_del">{{$t("button.del")}}</el-button>
                <el-button size="small" text type="primary"  @click="openAuthority(scope.row)">{{$t("button.authority")}}</el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>
     <menu-form ref="addOrUpdate" @refreshDataList="getList"></menu-form>
     <authorityView  ref="authorityView_"/>
    </div>
</template>
<script setup>
    import { ref,getCurrentInstance } from 'vue'
    import menuForm from './menu-form'
    import authorityView from './authorityView'
    import Api_ from '@/api/index'
    import { ElNotification,ElMessageBox } from 'element-plus'
    import { questStore } from '@/store/quest'
    const { $t } = getCurrentInstance().proxy;

    const menuName = ref(null)
    const loading = ref(false)
    const menuList = ref([])
    const addOrUpdate = ref(null)
    const authorityView_ = ref([])
    //按钮权限
    const sys_menu_add = ref(true)
    const sys_menu_edit = ref(true)
    const sys_menu_del = ref(true)

    const getList = ()=>{//获取菜单列表
        loading.value = true;
        Api_.fetchMenuTree({tenantId:questStore().getTenantId,menuName:menuName.value}).then(res=>{
            menuList.value = res.data
            loading.value = false;
        })
    }
    const addOrUpdateHandle = (isEdit, item = 1,)=>{//添加根菜单，添加子菜单，修改菜单
        addOrUpdate.value.init(isEdit, item)
    }
    const openAuthority = (e)=>{//打开商户授权
        authorityView_.value.init(e)
    }
    const handleDelete = (row)=>{
        ElMessageBox.confirm($t("alert.selEnter"), $t("tyle_.warn"), {
            confirmButtonText: $t("button.enter"),
            cancelButtonText: $t("button.close"),
            type: 'warning',
        }).then(()=>{
            Api_.delMenu(row.id).then(res=>{
                if(res.code ===0 ){
                    getList()
                    ElNotification({
                        message: $t("alert.succDel"),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        })
    }
    getList()
</script>

<style lang="scss" scoped>
    :deep(.el-dialog__body){
        padding-top: 0;
    }
</style>